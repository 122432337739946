module services {
    export module tariff {
        export class prohibitedAndRestrictedService implements interfaces.tariff.IProhibitedAndRestrictedService {
            static $inject = ["$resource", "ENV", "$http", "generalService", "$timeout"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig, private $http: ng.IHttpService, public generalService: interfaces.applicationcore.IGeneralService,
                private $timeout: ng.ITimeoutService) {
            }

            loadProhibitedAndRestrictedList(): ng.resource.IResourceClass<interfaces.tariff.IProhibitedAndRestricted> {
                return this.$resource<interfaces.tariff.IProhibitedAndRestricted>(this.ENV.DSP_URL + "ProhibitedAndRestricted/GetList", {
                    connectSearch: '@connectSearch',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1
                            }
                        }
                    });
            }

            GetPublishedRestrictions(): ng.resource.IResourceClass<interfaces.tariff.IProhibitedAndRestricted> {
                return this.$resource<interfaces.tariff.IProhibitedAndRestricted>(this.ENV.DSP_URL + "ProhibitedAndRestricted/GetPublishedRestrictions", {
                    search: '@@search'
                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                            }
                        }
                    });
            }

            getDetail(): ng.resource.IResourceClass<interfaces.tariff.IProhibitedAndRestrictedDetail> {
                return this.$resource<interfaces.tariff.IProhibitedAndRestrictedDetail>(this.ENV.DSP_URL + "ProhibitedAndRestricted/GetDetail", {
                    parId: '@parId',
                }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            getAgencyDropdown(countryId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "ProhibitedAndRestricted/GetAgencyDropdown", {
                    countryId: countryId
                });
            }

            getTypesDropdown(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "ProhibitedAndRestricted/GetTypesDropdown", {
                });
            }

            getForDropDown(searchText:string,countryId:number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "ProhibitedAndRestricted/GetForDropDown", {
                    searchText: searchText,
                    countryId: countryId
                });
            }    
            
            getCheckRestrictionCountriesForDropDown(searchText:string,currentEntityId:number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "ProhibitedAndRestricted/GetCheckRestrictionCountriesForDropDown", {
                    searchText: searchText,
                    currentEntityId: currentEntityId
                });
            }                

            downloadForm(formId: number) {
                var download = document.createElement("a");
                document.body.appendChild(download);

                this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: this.ENV.DSP_URL + 'ProhibitedAndRestricted/GetFormFile?formId=' + formId
                }).then((response) => {
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    var contentDisposition = response.headers()['content-disposition'];
                    var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim() || 'FormDownload.pdf';
                    filename = filename.replace('"', '');
                    filename = filename.replace('"', '');

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        download.href = fileURL;
                        download.download = filename;
                        download.click();
                        download.remove();
                    }
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

        }
    }
    angular.module("app").service("prohibitedAndRestrictedService", services.tariff.prohibitedAndRestrictedService);
}